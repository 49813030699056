/**********************************************************/
/**********************************************************/
// FE PRE-QA IS CONNECTED TO PF QA!!!
/**********************************************************/
/**********************************************************/

/*
  platformUrl --> qa
  auth_username --> same app-uid as pre-qa
*/

export const environment = {
  production: false,
  app_version: '1.0.0',

  // PF
  platformUrl: 'https://public-hcp-api.ppp-pre-qa.apps.jnj', // FE DEV IS CONNECTED TO PF PRE-QA!!!
  auth_username: 'ba2071ed-3299-4caf-b4c5-a46a4527f195', // = application uid
  auth_password: 'AppPass123!',
  accept_header: 'application/vnd.c4t.public-hcp-api.v1+json',
  content_type_header: 'application/vnd.c4t.public-hcp-api.v1+json',

  // CMS
  cmsUrl: 'https://eu-cdn.contentstack.com',
  cmsApiKey: 'blt2043dcd74f319d2f',
  cmsAccessToken: 'cs6920a94fad9928a8a9f2f2fa',
  cmsEnvironment: 'pre-qa',

  // Brightcove
  brightcove_acc_id: '6202477157001',

  // Options
  refreshEvaluation: false,
  dev_remember_login_email: true,
  trans_loader_skip_cs_errors: true,

  // Stores
  appStore:  'https://apps.apple.com/us/app/care4today-2-0-hcp-pre-qa/id1531489278',
  playStore: 'https://play.google.com/store/apps/details?id=com.c4thcpmobile.preqa'
};
